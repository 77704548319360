.btn-primary,
.swal2-confirm {
  @include themify($themes) {
    background-color: themed("btnPrimary");
    border-color: themed("btnPrimary");
    color: themed("btnPrimaryText");
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    @include themify($themes) {
      background-color: themed("btnPrimaryHover");
      border-color: themed("btnPrimaryBorderHover");
      color: themed("btnPrimaryText");
    }
  }

  &:disabled {
    opacity: 0.65;
  }
}

.btn-outline-primary {
  @include themify($themes) {
    background-color: themed("btnOutlinePrimaryBackground");
    border-color: themed("btnOutlinePrimaryBorder");
    color: themed("btnOutlinePrimaryText");
  }

  &:hover:not(:disabled),
  &:active {
    @include themify($themes) {
      background-color: themed("btnOutlinePrimaryBackgroundHover");
      border-color: themed("btnOutlinePrimaryBorderHover");
      color: themed("btnOutlinePrimaryTextHover");
    }
  }
}

.btn-secondary,
.swal2-cancel {
  @include themify($themes) {
    background-color: themed("btnSecondary");
    border-color: themed("btnSecondaryBorder");
    color: themed("btnSecondaryText");
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    @include themify($themes) {
      background-color: themed("btnSecondaryHover");
      border-color: themed("btnSecondaryBorderHover");
      color: themed("btnSecondaryTextHover");
    }
  }

  &:disabled {
    opacity: 0.65;
  }

  &:focus,
  &.focus {
    @include themify($themes) {
      box-shadow: 0 0 0 $btn-focus-width
        rgba(mix(color-yiq(themed("primary")), themed("primary"), 15%), 0.5);
    }
  }
}

.btn-outline-secondary {
  @include themify($themes) {
    background-color: themed("btnOutlineSecondaryBackground");
    border-color: themed("btnOutlineSecondaryBorder");
    color: themed("btnOutlineSecondaryText");
  }

  &:hover:not(:disabled),
  &:active {
    @include themify($themes) {
      background-color: themed("btnOutlineSecondaryBackgroundHover");
      border-color: themed("btnOutlineSecondaryBorderHover");
      color: themed("btnOutlineSecondaryTextHover");
    }
  }
}

.show > .btn-outline-secondary {
  &.dropdown-toggle,
  &:focus {
    @include themify($themes) {
      background-color: themed("btnOutlineSecondaryBackground");
      border-color: themed("btnOutlineSecondaryBorder");
      color: themed("btnOutlineSecondaryText");
    }
  }

  &:hover {
    @include themify($themes) {
      background-color: themed("btnOutlineSecondaryBackgroundHover");
      border-color: themed("btnOutlineSecondaryBorderHover");
      color: themed("btnOutlineSecondaryTextHover");
    }
  }
}

.btn-danger,
.swal2-deny {
  @include themify($themes) {
    background-color: themed("btnDanger");
    border-color: themed("btnDanger");
    color: themed("btnDangerText");
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    @include themify($themes) {
      background-color: themed("btnDangerHover");
      border-color: themed("btnDangerHover");
      color: themed("btnDangerText");
    }
  }
}

.btn-outline-danger {
  @include themify($themes) {
    background-color: themed("btnOutlineDangerBackground");
    border-color: themed("btnOutlineDangerBorder");
    color: themed("btnOutlineDangerText");
  }

  &:hover:not(:disabled),
  &:active {
    @include themify($themes) {
      background-color: themed("btnOutlineDangerBackgroundHover");
      border-color: themed("btnOutlineDangerBorderHover");
      color: themed("btnOutlineDangerTextHover");
    }
  }
}

.btn-link {
  &:focus,
  &.focus {
    outline-color: -webkit-focus-ring-color;
    outline-offset: 1px;
    outline-style: auto;
    outline-width: 1px;
  }

  &:not(.text-danger):not(.cursor-move) {
    @include themify($themes) {
      color: themed("btnLinkText");
    }
  }

  &:hover:not(.text-danger):not(.cursor-move) {
    @include themify($themes) {
      color: themed("btnLinkTextHover");
    }
  }
}

.btn-submit {
  position: relative;

  .bwi-spinner {
    align-items: center;
    bottom: 0;
    display: none;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:disabled:not(.manual),
  &.loading {
    .bwi-spinner {
      display: flex;
    }

    span {
      visibility: hidden;
    }
  }
}

button.no-btn {
  background: transparent;
  border: none;
  padding: 0;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    background: transparent;
    box-shadow: none;
    color: inherit;
  }
}
